import React from 'react';
import {
  Dialog, DialogContent, DialogTitle,
} from '@mui/material';

interface UploadProgressModalProps {
  progress?: string;
}

const UploadProgressModal: React.FC<UploadProgressModalProps> = ({
  progress,
}) => {
  return (
    <Dialog open={!!progress}>
      <DialogTitle>ファイルアップロード中</DialogTitle>
      <DialogContent>
        {progress}<br/><br/>
        ※ ファイルのアップロード・解析には数分程度かかる場合がございます。
      </DialogContent>
    </Dialog>
  );
};

export default UploadProgressModal;
