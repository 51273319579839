import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import { Plan } from '@/components/features/subscription/subscription.type';
import { ReqUpdateQuotaCustomization, ReqScheduleLicenseChange, ReqSchedulePlanChange, SvSubscriptionWithFull } from '../subscription.type';
import { useErrorModal } from '@/components/features/generic/hooks/useErrorModal';
import SvSubscriptionDetailPresenter from '../presenters/SvSubscriptionDetailPresenter';
import { AxiosError } from 'axios';
import { useConfirmModal } from '@/components/features/generic/hooks/useConfirmModal';
import { displayDate } from '@/lib/utils';

const SvSubscriptionDetailContainer: React.FC = () => {
  const [ plans, setPlans ] = useState<Plan[]>([]);
  const [ subscriptions, setSubscriptions ] = useState<SvSubscriptionWithFull[]>([]);
  const { setUnknownErrorModal, setErrorModal } = useErrorModal();
  const { setConfirmModal } = useConfirmModal();
  const team = useSelector((state: RootState) => state.supervisor.team.currentTeam);
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);

  useEffect(() => {
    if (!team) return

    const fetchData = async () => {
      try {
        const [rePlans, resSubscriptions] = await Promise.all([
          apiAxios.get<Plan[]>('/supervisor/plans'),
          apiAxios.get<SvSubscriptionWithFull[]>(`/supervisor/teams/${team.id}/subscriptions`),
        ]);
        let plans = rePlans.data;
        console.log("hasPrivilege", loginUser?.supervisor?.hasPrivilege);
        if (!loginUser?.supervisor?.hasPrivilege) {
          plans = plans.filter(plan => plan.type !== 'deleted');
        }

        setPlans(plans);
        setSubscriptions(resSubscriptions.data);
      } catch (error) {
        captureException(error);
        setUnknownErrorModal();
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, [team]);

  if (!team) {
    return null;
  }

  const handleSchedulePlanChange = async (data: ReqSchedulePlanChange) => {
    let title = "プラン変更の確認";
    let content = "プランの変更を予約しますか？";

    if (plans.some(plan => plan.id === data.nextPlanId && plan.type === 'deleted')) {
      title = "このチームを削除します。本当によろしいですか？";
      content = `⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️
⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️

あなたは下記のチームを「${displayDate(data.applyStartedOn)}」に削除しようとしています。
本当に削除して問題ないか。ご確認ください。

----------------------------------------------
[チーム情報]
ID: ${team.id}
名前: ${team.name}
企業: ${team.companyName}
ドメイン: ${team.primaryDomain}
作成者: ${team.creator?.name} (${team.creator?.email})

[特権管理者一覧]
${team.memberships.map(member => `・${member.user.name}(${member.user.email})`).join('\n')}

[今月の利用状況]
スレッド作成数: ${team.usage.threadNum}
生成回数: ${team.usage.aiGenerateCount}
消費クレジット数: ${team.usage.totalCredit}

[メモ]
${team.memo}
----------------------------------------------

⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️
⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️⚠️
`;
    }

    setConfirmModal(
      title,
      content,
      async () => {
        try {
          await apiAxios.post(`/supervisor/teams/${team.id}/subscriptions/plan/scheduled`, data);
          window.alert(
            '[予約成功]\n\n' +
            'プランの変更予約が正常に完了しました。\n画面をリロードします。'
          );
          window.location.reload();
        } catch (error) {
          if (error instanceof AxiosError && error.response && error.response.status === 400) {
            setErrorModal('エラー', error.response.data['detail']);
          } else {
            captureException(error);
            setUnknownErrorModal();
            console.error('Failed to save data:', error);
          }
        }
      }
    );
  };

  const handleScheduleLicenseChange = async (data: ReqScheduleLicenseChange) => {
    setConfirmModal(
      "ライセンス変更の確認",
      "ライセンスの変更を予約しますか？",
      async () => {
        try {
          await apiAxios.post(`/supervisor/teams/${team.id}/subscriptions/license/scheduled`, data);
          window.alert(
            '[予約成功]\n\n' +
            'ライセンスの変更予約が正常に完了しました。\n画面をリロードします。'
          );
          window.location.reload();
        } catch (error) {
          if (error instanceof AxiosError && error.response && error.response.status === 400) {
            setErrorModal('エラー', error.response.data['detail']);
          } else {
            captureException(error);
            setUnknownErrorModal();
            console.error('Failed to save data:', error);
          }
        }
      }
    );
  };

  const handleUpdateQuotaCustomization = async (subscriptionId: string, data: ReqUpdateQuotaCustomization) => {
    setConfirmModal(
      "プランカスタマイズの確認",
      "プランのカスタマイズを実行しますか？",
      async () => {
        try {
          await apiAxios.put(`/supervisor/subscriptions/${subscriptionId}/quota-customization`, data);
          window.alert(
            '[実行成功]\n\n' +
            'プランのカスタマイズが正常に完了しました。\n画面をリロードします。'
          );
          window.location.reload();
        } catch (error) {
          if (error instanceof AxiosError && error.response && error.response.status === 400) {
            setErrorModal('エラー', error.response.data['detail']);
          } else {
            captureException(error);
            setUnknownErrorModal();
            console.error('Failed to save data:', error);
          }
        }
      }
    );
  };

  const cancelScheduledPlanChange = async () => {
    setConfirmModal(
      "キャンセル確認",
      "プランの変更予約をキャンセルしますか？",
      async () => {
        try {
          await apiAxios.delete(`/supervisor/teams/${team.id}/subscriptions/plan/scheduled`);
          window.alert(
            '[キャンセル成功]\n\n' +
            'プランの変更予約がキャンセルされました。\n画面をリロードします。'
          );
          window.location.reload();
        } catch (error) {
          captureException(error);
          setUnknownErrorModal();
          console.error('Failed to save data:', error);
        }
      }
    );
  };

  const handleCancelLicenseChangeScheduled = async () => { // FIXME: handleCancelHoge か cancelHoge かが不統一
    setConfirmModal(
      "キャンセル確認",
      "ライセンスの変更予約をキャンセルしますか？",
      async () => {
        try {
          await apiAxios.delete(`/supervisor/teams/${team.id}/subscriptions/license/scheduled`);
          window.alert(
            '[キャンセル成功]\n\n' +
            'ライセンスの変更予約がキャンセルされました。\n画面をリロードします。'
          );
          window.location.reload();
        } catch (error) {
          captureException(error);
          setUnknownErrorModal();
          console.error('Failed to save data:', error);
        }
      }
    );
  };

  return (
    <SvSubscriptionDetailPresenter
      team={team}
      plans={plans}
      subscriptions={subscriptions}
      onSchedulePlanChange={handleSchedulePlanChange}
      onScheduleLicenseChange={handleScheduleLicenseChange}
      onHandleQuotaCustomization={handleUpdateQuotaCustomization}
      onCancelPlanChange={cancelScheduledPlanChange}
      onCancelLicenseChange={handleCancelLicenseChangeScheduled}
    />
  );
};

export default SvSubscriptionDetailContainer;
