import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ReqUpdateQuotaCustomization, SvSubscriptionWithFull } from '../subscription.type';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { WaTextField } from '@/components/ui/WaTextField';


/** Vault有効化設定 */
enum VaultEnabledLabel {
  ENABLED = "有効",
  DISABLED = "無効",
  UNDEFINED = "指定なし"
}

const vaultEnabledLabelToBoolean = (ve: string): boolean | null => {
  switch (ve) {
    case VaultEnabledLabel.ENABLED:
      return true
    case VaultEnabledLabel.DISABLED:
      return false
    default:
      return null
  }
}

const vaultEnabledBooleanToLabel = (ve: boolean | null): VaultEnabledLabel => {
  switch (ve) {
    case true:
      return VaultEnabledLabel.ENABLED
    case false:
      return VaultEnabledLabel.DISABLED
    default:
      return VaultEnabledLabel.UNDEFINED
  }
}

// ReqUpdateQuotaCustomization の型を拡張した新しい型
interface ReqUpdateQuotaCustomizationForm extends ReqUpdateQuotaCustomization {
  vaultEnabledLabel: VaultEnabledLabel; // vaultEnabled プロパティの型を変更
}


const schema = z.object({
  maxAiGenerateCount: z.union([
    z.nullable(z.string().refine(
      (value) => value === '' || (Number(value) >= 0 && Number.isInteger(Number(value))),
      "空欄または 0 以上の整数値を入力してください"
    )),
    z.number().int().min(0)
  ]),
  maxInputCharsLength: z.union([
    z.nullable(z.string().refine(
      (value) => value === '' || (Number(value) >= 0 && Number.isInteger(Number(value))),
      "空欄または 0 以上の整数値を入力してください"
    )),
    z.number().int().min(0)
  ]),
  vaultEnabledLabel: z.enum([
    VaultEnabledLabel.ENABLED,
    VaultEnabledLabel.DISABLED,
    VaultEnabledLabel.UNDEFINED,
  ]),
});

interface Props {
  open: boolean;
  handleClose: () => void;
  onSubmit: (data: ReqUpdateQuotaCustomization) => void;
  subscription: SvSubscriptionWithFull;
}

const SvQuotaCustomizationFormModal: React.FC<Props> = ({
  open,
  handleClose,
  onSubmit,
  subscription,
}) => {
  const team = useSelector((state: RootState) => state.supervisor.team.currentTeam); // TODO: subscription の切り替えが必要

  if (!(team && subscription)) {
    return null;
  }

  const { register, handleSubmit, reset, formState: { errors } } = useForm<ReqUpdateQuotaCustomizationForm>({
    resolver: zodResolver(schema),
    defaultValues: {
      maxAiGenerateCount: subscription.quotaCustomization.maxAiGenerateCount,
      maxInputCharsLength: subscription.quotaCustomization.maxInputCharsLength,
      vaultEnabledLabel: vaultEnabledBooleanToLabel(subscription.quotaCustomization.vaultEnabled)
    },
  });

  // モーダルを開くたびにフォームの値をリセット
  useEffect(() => {
    if (open) {
      reset({
        maxAiGenerateCount: subscription.quotaCustomization.maxAiGenerateCount,
        maxInputCharsLength: subscription.quotaCustomization.maxInputCharsLength,
        vaultEnabledLabel: vaultEnabledBooleanToLabel(subscription.quotaCustomization.vaultEnabled)
      })
    }
  }, [open])

  const onFormSubmit = async (data: ReqUpdateQuotaCustomizationForm) => {
    onSubmit({
      maxAiGenerateCount: data.maxAiGenerateCount ? Number(data.maxAiGenerateCount) : null,
      maxInputCharsLength: data.maxInputCharsLength ? Number(data.maxInputCharsLength) : null,
      vaultEnabled: vaultEnabledLabelToBoolean(data.vaultEnabledLabel)
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>プランのカスタマイズ</DialogTitle>
      <DialogContent>
        <TableContainer sx={{ mb: 5 }}>
          <Typography variant="h6" gutterBottom>
              現在の内容
          </Typography>
          <Table style={{ width: 600 }}>
            <TableHead>
              <TableRow>
                <TableCell>プラン</TableCell>
                <TableCell>期間</TableCell>
                <TableCell>ライセンス数</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {subscription.plan.name}
                  {!!team.scheduledSubscription && (
                    <Typography variant="caption" color="error" component="span">
                      {' '}(変更予約あり)
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  {team.currentSubscription.latestContractTerm[0]} 〜 {team.currentSubscription.latestContractTerm[1]}
                </TableCell>
                <TableCell>
                  {subscription.currentLicense?.amount}
                  {!!subscription.scheduledLicense && (
                    <Typography variant="caption" color="error" component="span">
                      {' '}(変更予約あり)
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Typography variant="h6" gutterBottom>
              変更内容
          </Typography>
          <FormControl fullWidth margin="dense" variant="outlined" error={Boolean(errors.maxAiGenerateCount)}>
            <WaTextField
              type="text" // number型 では 未入力を受け付けられないので text 型
              label="最大AI生成回数"
              {...register('maxAiGenerateCount')}
              error={!!errors.maxAiGenerateCount}
              helperText={errors.maxAiGenerateCount?.message}
            />
          </FormControl>
          <Typography variant='caption' gutterBottom>
            ※ 未入力の場合プランのデフォルト値（{subscription.plan.quotaTemplate.maxAiGenerateCount || "制限なし"}）が適用されます。
          </Typography>
          <FormControl fullWidth margin="dense" variant="outlined" error={Boolean(errors.maxInputCharsLength)}>
            <WaTextField
              type="text"
              label="最大入力文字数"
              {...register('maxInputCharsLength')}
              error={!!errors.maxInputCharsLength}
              helperText={errors.maxInputCharsLength?.message}
            />
          </FormControl>
          <Typography variant='caption' gutterBottom>
            ※ 未入力の場合プランのデフォルト値（{subscription.plan.quotaTemplate.maxInputCharsLength || "制限なし"}）が適用されます。
          </Typography>
          <FormControl fullWidth margin="dense" variant="outlined" error={Boolean(errors.vaultEnabledLabel)}>
            <InputLabel>Vault連携機能</InputLabel>
            <Select
              label="Vault連携機能"
              defaultValue={vaultEnabledBooleanToLabel(subscription.quotaCustomization.vaultEnabled)}
              {...register('vaultEnabledLabel')}
              error={!!errors.vaultEnabledLabel}
            >
              <MenuItem value={VaultEnabledLabel.ENABLED}>{VaultEnabledLabel.ENABLED}</MenuItem>
              <MenuItem value={VaultEnabledLabel.DISABLED}>{VaultEnabledLabel.DISABLED}</MenuItem>
              <MenuItem value={VaultEnabledLabel.UNDEFINED}>{VaultEnabledLabel.UNDEFINED}（{vaultEnabledBooleanToLabel(subscription.plan.quotaTemplate.vaultEnabled)}）</MenuItem>
            </Select>
          </FormControl>
          <DialogActions>
            <Button onClick={handleClose}>キャンセル</Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
            >
              保存
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SvQuotaCustomizationFormModal;
