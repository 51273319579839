
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControlLabel, Checkbox, Button } from '@mui/material';

const FileWarningModal: React.FC<{
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  dontShowAgain: boolean;
  setDontShowAgain: (value: boolean) => void;
}> = ({ open, onClose, onConfirm, dontShowAgain, setDontShowAgain }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>ファイル送信の確認</DialogTitle>
    <DialogContent>
      <DialogContentText>
        送信前に添付ファイルの内容を今一度ご確認ください。<br/>
        個人情報や機密情報が意図せず含まれていないかを十分にご確認いただき、問題がなければご送信ください。
      </DialogContentText>
      <FormControlLabel
        control={<Checkbox checked={dontShowAgain} onChange={(e) => setDontShowAgain(e.target.checked)} />}
        label="次回から表示しない"
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        キャンセル
      </Button>
      <Button onClick={onConfirm} color="primary" autoFocus>
        OK
      </Button>
    </DialogActions>
  </Dialog>
);

export default FileWarningModal;