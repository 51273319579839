import React from 'react';
import { NavLink } from 'react-router-dom';
import { List, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import { Message, Upgrade, Approval, History, EditNote, Person, Block, Security, Group, TableChart, HelpOutline, Timeline} from '@mui/icons-material';
import { LeftSidebarSubTitle } from '@/components/ui/StyledComponents';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { getCurrentMembership, getCurrentSubscription, isSuperAdmin } from '../../auth/auth.type';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { ADMIN_MANUAL_URL } from '@/config';
import SettingsIcon from '@mui/icons-material/Settings';

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;

  &.active {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;
const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;

  &.active {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

const StyledListItemButton = styled(ListItemButton)`
  padding-left: 16px;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 40px;
`;

const StyledListItemText = styled(ListItemText)`
  margin: 0;
  & .MuiListItemText-primary {
    font-size: 0.875rem;
  }
`;

const AdminLeftSidebar: React.FC<{ onItemClick: () => void }> = ({ onItemClick }) => {
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const currentSub = getCurrentSubscription(loginUser);
  const membership = getCurrentMembership(loginUser)
  if (!loginUser || !currentSub || !membership) {
    return;
  }
  const quota = membership.team.quota;

  return (
    <List component="div" disablePadding>
      { isSuperAdmin(loginUser) && <>
        <LeftSidebarSubTitle sx={{ display: 'flex', alignItems: 'center' }}>
          チーム
          <VerifiedUserIcon fontSize="inherit" sx={{ ml: 0.5, mb: '2px', color: 'green' }} />
        </LeftSidebarSubTitle>
        <StyledNavLink to="/admin/team" onClick={onItemClick}>
          <StyledListItemButton>
            <StyledListItemIcon>
              <EditNote />
            </StyledListItemIcon>
            <StyledListItemText primary={"チーム基本情報"} />
          </StyledListItemButton>
        </StyledNavLink>
        <StyledNavLink to="/admin/team/config" onClick={onItemClick}>
          <StyledListItemButton>
            <StyledListItemIcon>
              <SettingsIcon />
            </StyledListItemIcon>
            <StyledListItemText primary={"機能設定"} />
          </StyledListItemButton>
        </StyledNavLink>
        </>
      }

      <LeftSidebarSubTitle>メンバー・グループ</LeftSidebarSubTitle>
      <StyledNavLink to="/admin/memberships" onClick={onItemClick}>
        <StyledListItemButton>
          <StyledListItemIcon>
            <Person />
          </StyledListItemIcon>
          <StyledListItemText primary={"メンバー管理"} />
        </StyledListItemButton>
      </StyledNavLink>
      <StyledNavLink to="/admin/membership-groups" onClick={onItemClick}>
        <StyledListItemButton>
          <StyledListItemIcon>
            <Group />
          </StyledListItemIcon>
          <StyledListItemText primary={"グループ管理"} />
        </StyledListItemButton>
      </StyledNavLink>

      { isSuperAdmin(loginUser) && <>
          <LeftSidebarSubTitle sx={{ display: 'flex', alignItems: 'center' }}>
            契約
            <VerifiedUserIcon fontSize="inherit" sx={{ ml: 0.5, mb: '2px', color: 'green' }} />
          </LeftSidebarSubTitle>
          <StyledNavLink to="/admin/subscription" onClick={onItemClick}>
            <StyledListItemButton>
              <StyledListItemIcon>
                <Approval />
              </StyledListItemIcon>
              <StyledListItemText primary={"契約内容"} />
            </StyledListItemButton>
          </StyledNavLink>
          <StyledNavLink to="/admin/subscription/upgrade-contact" onClick={onItemClick}>
            <StyledListItemButton>
              <StyledListItemIcon>
                <Upgrade />
              </StyledListItemIcon>
              <StyledListItemText primary={"アップグレード"} />
            </StyledListItemButton>
          </StyledNavLink>
        </>
      }
      {
        isSuperAdmin(loginUser) && <>
          <LeftSidebarSubTitle sx={{ display: 'flex', alignItems: 'center' }}>
            利用状況
            <VerifiedUserIcon fontSize="inherit" sx={{ ml: 0.5, mb: '2px', color: 'green' }} />
          </LeftSidebarSubTitle>
          <StyledNavLink to="/admin/usage/latest-statistics" onClick={onItemClick}>
            <StyledListItemButton>
              <StyledListItemIcon>
                <TableChart />
              </StyledListItemIcon>
              <StyledListItemText primary={"最新の利用状況"} />
            </StyledListItemButton>
          </StyledNavLink>
          <StyledNavLink to="/admin/usage/reports" onClick={onItemClick}>
            <StyledListItemButton>
              <StyledListItemIcon>
                <Timeline />
              </StyledListItemIcon>
              <StyledListItemText primary={"集計レポート"} />
            </StyledListItemButton>
          </StyledNavLink>
        </>
      }
      {
        isSuperAdmin(loginUser) && quota.vaultEnabled && <>
          <LeftSidebarSubTitle sx={{ display: 'flex', alignItems: 'center' }}>
            VAULT
            <VerifiedUserIcon fontSize="inherit" sx={{ ml: 0.5, mb: '2px', color: 'green' }} />
          </LeftSidebarSubTitle>
          <StyledNavLink to="/admin/vault/usages" onClick={onItemClick}>
            <StyledListItemButton>
              <StyledListItemIcon>
                <History />
              </StyledListItemIcon>
              <StyledListItemText primary={"利用履歴"} />
            </StyledListItemButton>
          </StyledNavLink>
          <StyledNavLink to="/admin/vault/messages" onClick={onItemClick}>
            <StyledListItemButton>
              <StyledListItemIcon>
                <Message />
              </StyledListItemIcon>
              <StyledListItemText primary={"メッセージ履歴"} />
            </StyledListItemButton>
          </StyledNavLink>
          <StyledNavLink to="/admin/vault/files" onClick={onItemClick}>
            <StyledListItemButton>
              <StyledListItemIcon>
                <Message />
              </StyledListItemIcon>
              <StyledListItemText primary={"ファイルアップロード履歴"} />
            </StyledListItemButton>
          </StyledNavLink>
        </>
      }
      <LeftSidebarSubTitle>セキュリティ</LeftSidebarSubTitle>
      <StyledNavLink to="/admin/dlp/expression-configs" onClick={onItemClick}>
        <StyledListItemButton>
          <StyledListItemIcon>
            <Block />
          </StyledListItemIcon>
          <StyledListItemText primary={"機密ワードブロッカー設定"} />
        </StyledListItemButton>
      </StyledNavLink>
      <StyledNavLink to="/admin/dlp/info-type-configs" onClick={onItemClick}>
        <StyledListItemButton>
          <StyledListItemIcon>
            <Security />
          </StyledListItemIcon>
          <StyledListItemText primary={"DLP設定"} />
        </StyledListItemButton>
      </StyledNavLink>
      <LeftSidebarSubTitle>その他</LeftSidebarSubTitle>
      <StyledLink
        href={ADMIN_MANUAL_URL}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(e) => {
          e.preventDefault();
          window.open(ADMIN_MANUAL_URL, '_blank', 'noopener,noreferrer');
          onItemClick();
        }}
      >
        <StyledListItemButton>
          <StyledListItemIcon>
            <HelpOutline />
          </StyledListItemIcon>
          <StyledListItemText primary={"管理者マニュアル"} />
        </StyledListItemButton>
      </StyledLink>
    </List>
  );
};

export default AdminLeftSidebar;
