import { MembershipRole } from "../membership/membership.constant";
import { MemberInvite, Membership } from "../membership/membership.type";
import { SubscriptionDetail } from "../subscription/subscription.type";
import { TeamLogo, TeamWithSubscription } from "../team/team.type";

export type User = {
  id: string;
  name: string;
  email: string;
  picture: string;
  isServiceUsable: boolean;
}

export type Supervisor = {
  id: string;
  hasPrivilege: boolean;
}

export type LoginUser = User & {
  memberships: LoginUserMembership[];
  supervisor?: Supervisor;
}

export type LoginUserTeam = TeamWithSubscription & {
  membersCount: number;
  logo?: TeamLogo
}

export type LoginUserMembership = Membership & {
  team: LoginUserTeam;
  invite?: MemberInvite;
  uiFlags: Record<string, unknown>;
  mentionedAiModelCodesAtLastThreadCreation?: string[],
};

export function getCurrentMembership(user?: LoginUser): LoginUserMembership | undefined {
  if (!user) {
    return undefined;
  }
  return user.memberships.find((m) => m.isCurrent);
}

export function getCurrentSubscription(user?: LoginUser): SubscriptionDetail | undefined {
  const membership = getCurrentMembership(user);
  return membership?.team.currentSubscription;
}

export function hasRole(user: LoginUser, role: MembershipRole) {
  const membership = getCurrentMembership(user);
  return membership?.role === role;
}

export function isAdmin(user?: LoginUser) {
  if (!user) {
    return false;
  }
  return hasRole(user, MembershipRole.ADMIN) || hasRole(user, MembershipRole.SUPER_ADMIN);
}

export function isSuperAdmin(user?: LoginUser) {
  if (!user) {
    return false;
  }
  return hasRole(user, MembershipRole.SUPER_ADMIN);
}

export function isSystemSupervisor(user?: LoginUser) {
  if (!user) {
    return false;
  }
  return !!user.supervisor;
}

export function getJoinMemberships(user: LoginUser): LoginUserMembership[] {
  return user.memberships.filter((m) => m.isJoined);
}

export function getUiFlagValue(user: LoginUser | undefined, key: string): unknown | undefined | null {
  const membership = getCurrentMembership(user);
  return membership?.uiFlags?.[key];
}
