import React from 'react';
import { Paper, Avatar, Typography, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
import theme from '@/theme';
import FileDownloadButton from '../../file/components/FileDownloadButton';
import { FileUpload } from '../../file/file.type';
import { convertMimeTypeToName, getTypeLabelForMimeType } from '../../file/file.utils';

interface AttachedFileInfoProps {
  file: File | FileUpload | undefined;
  onRemove?: () => void;
  backgroundColor?: string;
  downloadable?: boolean;
}

const FileInfoContainer = styled(Paper)<{ bgcolor: string }>`
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 12px;
  background-color: ${props => props.bgcolor};
  max-width: 400px;
  min-width: 175px;
  ${theme.breakpoints.down('sm')} {
    min-width: 50px;
  }

  width: auto;
  border-radius: 4px;

  &:hover .close-icon {
    opacity: 1;
    transition: opacity 0.01s ease-in-out;
  }

  .close-icon {
    display: inline-flex;
    opacity: 0;
    transition: opacity 0.01s ease-in-out;
  }
`;

const FileIcon = styled(Avatar)`
  margin-right: 8px;
  font-size: 15px;
  font-weight: bold;
  ${theme.breakpoints.down('sm')} {
    margin-right: 0;
  }
`;

const FileDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;

  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  background-color: ${theme.palette.primary.main} !important;
  color: ${theme.palette.common.white};
  top: -15px;
  right: -15px;
`;

const FileNameTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
`;


const AttachedFileInfo: React.FC<AttachedFileInfoProps> = ({
  file, onRemove, backgroundColor, downloadable = false
}) => {
  if (!file) return null;

  let fileType = '不明なファイルタイプ';
  let fileName = '不明なファイル名';
  let fileIcon = null;

  const isUploadedFile = 'type' in file && 'name' in file;
  const isExistingFile = 'mimetype' in file && 'filename' in file;

  if (isUploadedFile || isExistingFile) {
    fileType = isUploadedFile ? file.type : file.mimetype;
    fileName = isUploadedFile ? file.name : file.filename;
    const isImage = fileType.startsWith('image/');
    const fileSrc = isUploadedFile
      ? URL.createObjectURL(file)
      : `data:image/*;base64,${file.thumbnail}`;

    fileIcon = isImage ? (
      <img src={fileSrc} alt={fileName} style={{ width: 40, height: 40, backgroundColor: 'white' }} />
    ) : (
      <FileIcon variant="rounded">{fileName.split('.').pop()?.toUpperCase()}</FileIcon>
    );
  }

  return (
    <FileInfoContainer bgcolor={backgroundColor || theme.palette.background.default}>
      {fileIcon}
      <FileDetails>
        <FileNameTypography variant="body2">{fileName}</FileNameTypography>
        <Typography variant="caption">{getTypeLabelForMimeType(fileType)} {convertMimeTypeToName(fileType)}</Typography>
      </FileDetails>
      {
        onRemove &&
        <CloseButton className="close-icon" size="small" disableFocusRipple={true} onClick={onRemove}>
          <CloseIcon fontSize="small" />
        </CloseButton>
      }
      {
        downloadable && isExistingFile &&
        <Box sx={{ ml: 1, mr: -1 }}>
          <FileDownloadButton file={file} variant="icon"/>
        </Box>
      }
    </FileInfoContainer>
  );
};

export default AttachedFileInfo;
