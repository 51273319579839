import React, { useRef } from 'react';
import { AIMessage, MessageNode } from '../thread.type';
import styled from '@emotion/styled';
import { Avatar, Box, Button, IconButton, Typography } from '@mui/material';
import { AIMessageStatus, MessageType } from '../thread.constant';
import { useSelector } from 'react-redux';
import { RootState } from '@/store';
import { AIModelConsts } from '../../aiModel/aiModel.constant';
import { restoreMask } from '../thread.utils';
import useThreadApi from '../hooks/useThreadApi';
import ThreadPostWordReplacedInfo from './ThreadPostReplacementInfo';
import { displayTimestamp } from '@/lib/utils';
import theme from '@/theme';
import AiGenerateStatus from '../components/AiGenerateStatus';
import { PostInfoWrapper } from './ThreadPost';
import { copyToClipboard, getOuterHtmlFromElement } from '@/common/utils/copy';
import { useToast } from '../../generic/hooks/useToast';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CustomMarkdown from '@/components/ui/CustomMarkdown';

interface Props {
  node: MessageNode;
  aiMessage: AIMessage;
  minimizeHeaderInfo?: boolean;
  scroll?: boolean;
}

const AIPostWrapper = styled.div<{ scroll: boolean }>`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  align-self: flex-start;
  background-color: #fff;
  border: 1px solid #ddd;

  ${props => props.scroll ? 'overflow-y: scroll; height: 100%;' : ''}

  ${theme.breakpoints.up('md')} {
    max-width: min(1000px, 100%);
  }

  pre {
    white-space: pre-wrap;
  }

  .hover-content {
    display: none;
  }
  &:hover .hover-content {
    display: block;
  }
`;

const CustomMarkdownWrapper = styled.div`
  padding: 20px 5px;
`;

const ThreadPostAiMessage: React.FC<Props> = ({node, aiMessage, minimizeHeaderInfo = false, scroll = false}) => {
  const copyAnchor = useRef<HTMLDivElement>(null);
  const currentThread = useSelector((state: RootState) => state.thread.currentThread);
  const { retryAIMessage } = useThreadApi();
  const { showToast } = useToast();

  if (!currentThread) return <></>;

  const getAvatar = (aiMessage: AIMessage) : string => {
    const aiModel = AIModelConsts.find((model) => model.id === aiMessage.version.aiModel.code);
    return aiModel ? aiModel.avatar : '';
  }

  const copy = () => {
    if (copyAnchor.current) {
      const textHtml = getOuterHtmlFromElement(copyAnchor.current);
      copyToClipboard(
        aiMessage.body,
        textHtml,
        showToast,
      );
    }
  };

  return (
    <AIPostWrapper key={aiMessage.id} scroll={scroll}>
      <PostInfoWrapper>
        <Avatar src={ getAvatar(aiMessage) } />
        <Typography variant="body2" color="textSecondary">
          <b>{aiMessage.version.aiModel.name}</b>
          {!minimizeHeaderInfo && <>&nbsp;&nbsp;{displayTimestamp(node.createdAt)}</>}
        </Typography>
        <AiGenerateStatus aiMsg={aiMessage} hideSuccess={minimizeHeaderInfo}/>
        {/* ワード置換情報 */}
        {(() => {
          if (aiMessage.status !== AIMessageStatus.FINISHED) {
            return <></>
          }

          const replaced = restoreMask(aiMessage.body, currentThread.dlpReplaces)
          if (replaced === aiMessage.body) {
            return <></>
          }

          return (
            <ThreadPostWordReplacedInfo
              type={MessageType.AI}
              body={aiMessage.body}
              minimize={true}
            />
          )
        })()}
        <div style={{ flexGrow: 1 }} />
        <Box className="hover-content">
          <IconButton size="small" onClick={copy}>
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Box>
      </PostInfoWrapper>
      <CustomMarkdownWrapper ref={copyAnchor}>
        <CustomMarkdown markdown={
          aiMessage.status === AIMessageStatus.FINISHED ?
          restoreMask(aiMessage.body, currentThread.dlpReplaces) :
          aiMessage.body
        } />
      </CustomMarkdownWrapper>

      {/* エラー理由の表示 */}
      {(() => {
        if (aiMessage.status !== AIMessageStatus.FAILED) {
          return <></>
        }

        const failure = aiMessage.failures.length > 0 ? aiMessage.failures.reduce((a, b) => a.createdAt > b.createdAt ? a : b) : null;
        const failureCode = failure ? failure.code : 'unknown';
        const failureCanRetry = failure ? failure.canRetry : true;

        let message = <>リトライを押して再度生成を試みてください。</>;
        switch (failureCode) {
          case "max_tokens":
            message =  <>
              「{aiMessage.version.aiModel.name }」に入力できる最大文字数を超過しました。<br/>
              このスレッドではこれ以上やりとりを継続できませんので、新たにスレッドを作成してください。<br/>
              (これまでのやりとりを要約するなどして、生成AIに伝えてあげるとやり取りを継続しやすいでしょう)
            </>;
            break;
          case "invalid_content":
            message = <>
              下記に関するやりとりであると「{aiMessage.version.aiModel.name }」が判断したため、回答が中止されました。<br/>
              お手数おかけしますが、新規スレッドにて入力内容を調整しやり直してください。<br/>
              <Typography variant="caption" component="span" color="error" >
                ・ 安全性に問題がある内容 (政治的、宗教的、性的、差別的な内容など)<br/>
                ・ 著作権を侵害する内容<br/>
                ・ 他者のプライバシーを侵害する内容<br/>
                ・ ブロック対象となるワードが含まれた内容<br/>
                ・ その他、不適切と判断される内容
              </Typography>
            </>;
            break;
          case "server_busy":
            message = <>
              現在、「{aiMessage.version.aiModel.name }」のサーバーが混み合っているため、回答が中止されました。<br/>
              しばらくしてから再度リトライしてください。
            </>;
            break;
          case "internal_error":
            message = <>
              「{aiMessage.version.aiModel.name }」の内部でエラーが発生したようです。<br/>
              しばらくしてから再度リトライするか、入力内容を調整して投稿することで状況が改善する可能性があります。
            </>;
            break;
          case "loop_detected":
            message = <>
              ループが発生している可能性があるため、処理を停止いたしました。<br/>
              お手数ですが再度リトライいただくか、入力内容を調整して投稿することで状況が改善する可能性があります。
            </>;
            break;
          case "timeout":
            message = <>
              「{aiMessage.version.aiModel.name }」の回答がタイムアウトしました。<br/>
              しばらくしてから再度リトライしてください。
            </>;
            break;
          case "user_abort":
            message = <>
              生成が中断されました。
            </>;
            break;
        }

        return <>
          <Typography variant="body1" color="error" gutterBottom>
            {message}
          </Typography>
          { failureCanRetry &&
            <Button variant="outlined" color="primary" size="small"
              onClick={() => retryAIMessage(currentThread.id, node.id, aiMessage.id)}>
            リトライ
            </Button>}
        </>
      })()}
    </AIPostWrapper>
  );
}

export default ThreadPostAiMessage;
