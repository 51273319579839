import axios from 'axios';
import { FileUpload } from './file.type';
import apiAxios from '@/lib/axios';
import { FileAnalysisFailureReason, FileAnalysisStatus, FileType, MB, MimeTypeToName, UploadableAudioMaxMb, UploadableAudioMimeTypes, UploadableDocumentMaxMb, UploadableDocumentMimeTypes, UploadableImageMaxMb, UploadableImageMimeTypes, UploadableNotTextMaxMbForStarter, UploadableTxtMaxMb, UploadableTxtMimeTypes, UploadableVideoMimeTypes } from './file.constant';
import { LoginUserMembership } from '../auth/auth.type';
import { AIModelConst, DEFAULT_AI_MODEL_ID } from '../aiModel/aiModel.constant';
import { getAIModelConsts } from '../aiModel/aiModel.utils';
import * as pdfjsLib from 'pdfjs-dist';
import { captureException } from '@sentry/react';

// ワーカーのソースを設定
pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.mjs`;


export class FileUploadError extends Error {
  retryable: boolean;

  constructor(message: string, retryable: boolean = false) {
    super(message);
    this.name = 'FileUploadError';
    this.retryable = retryable;
  }
}

export const upload = async (
  file: File,
  onProgress: (progress: string) => void,
): Promise<FileUpload> => {
  if (isGcsUploadType(file)) {
    return uploadResumable(file, onProgress);
  }
  return uploadInstantly(file, onProgress);
}

export const uploadInstantly = async (
  file: File,
  onProgress: (progress: string) => void,
): Promise<FileUpload> => {
  const formData = new FormData();
  formData.append('file', file);

  const res = await apiAxios.post<FileUpload>('/files/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) => {
      if (progressEvent.total) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(`ファイルをアップロードしています。(進行率: ${percentCompleted}%)`);
      }
    },
  });
  return res.data;
}

export const uploadResumable = async (
  file: File,
  onProgress: (progress: string) => void,
): Promise<FileUpload> => {
  // アップロードURLを準備
  onProgress(`(1/3) ファイルアップロードの準備をしています。`);
  const res1 = await apiAxios.post<FileUpload>(
    '/files/upload/initiate',
    {
      filename: file.name,
      mimetype: file.type,
      filesize: file.size,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  const uploadUrl = res1.data.uploadUrl;
  if (!uploadUrl) {

    throw new FileUploadError('ファイルアップロードの準備に失敗しました。', true);
  }

  // アップロード
  const res2 = await axios.put(uploadUrl, file, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
    onUploadProgress: (progressEvent) => {
      if (progressEvent.total) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(`(2/3) ファイルをアップロードしています。(進行率: ${percentCompleted}%)`);
      }
    },
    validateStatus: (status) => {
      return status < 400; // 400未満のステータスを許容する
    },
  });

  if (!res2.status || (res2.status !== 200 && res2.status !== 201)) {
    const errorDetails = await res2.data;
    console.debug(`Failed to upload file: ${errorDetails}`);
    throw new FileUploadError('ファイルアップロードに失敗しました。', true);
  }

  // 解析開始
  onProgress(`(3/3) ファイルの内容を解析中です。`);
  const res3 = await apiAxios.post<FileUpload>(`/files/${res1.data.id}/analysis`);
  if (res3.status !== 200) {
    throw new FileUploadError('ファイルの解析に失敗しました。', true);
  }

  // 状態のポーリング
  const interval = 1000;
  const maxAttempts = 60 * 3;  // 3分まで
  let attempts = 0;
  let res4;
  do {
    res4 = await apiAxios.get<FileUpload>(`/files/${res1.data.id}`);
    attempts++;
    await new Promise((resolve) => setTimeout(resolve, interval));
  } while (
    (
      res4.data.analysisStatus != FileAnalysisStatus.DONE &&
      res4.data.analysisStatus != FileAnalysisStatus.FAILED
    ) && attempts < maxAttempts
  );

  const fileUpload = res4.data;


  if (fileUpload.analysisStatus === FileAnalysisStatus.FAILED) {
    if (fileUpload.analysisFailureReason == FileAnalysisFailureReason.DURATION_LIMIT_EXCEEDED) {
      throw new FileUploadError("音声の長さが長すぎます。(8.4時間まで)");
    }
    if (fileUpload.analysisFailureReason == FileAnalysisFailureReason.PAGE_NUM_LIMIT_EXCEEDED) {
      throw new FileUploadError("ページ数が多すぎます。(3000ページまで)");
    }
  }
  if (fileUpload.analysisStatus == FileAnalysisStatus.PENDING) {
    throw new FileUploadError("ファイルの解析が時間切れになりました。", true);
  }

  return res4.data;
}

export const isGcsUploadType = (file: File): boolean => {
  return [
    ...UploadableVideoMimeTypes,
    ...UploadableAudioMimeTypes,
    ...UploadableDocumentMimeTypes,
  ].includes(file.type);
}

export const convertMimeTypeToName = (mimeType: string) => {
  if (Object.prototype.hasOwnProperty.call(MimeTypeToName, mimeType)) {
    return MimeTypeToName[mimeType as keyof typeof MimeTypeToName];
  } else {
    return "その他";
  }
}

export const convertMimeTypeToFileType = (mimeType: string) : FileType | null => {
  if (UploadableImageMimeTypes.includes(mimeType)) {
    return FileType.IMAGE;
  } else if (UploadableAudioMimeTypes.includes(mimeType)) {
    return FileType.AUDIO;
  } else if (UploadableDocumentMimeTypes.includes(mimeType)) {
    return FileType.DOCUMENT;
  } else if (UploadableTxtMimeTypes.includes(mimeType)) {
    return FileType.TEXT;
  } else {
    return null;
  }
}

export const getLabelForFileType = (fileType: FileType) : string => {
  switch (fileType) {
    case FileType.IMAGE:
      return "画像";
    case FileType.AUDIO:
      return "音声";
    case FileType.DOCUMENT:
      return "文書";
    case FileType.TEXT:
      return "テキスト";
  }
  return "その他";
}

export const getTypeLabelForMimeType = (mimeType: string) : string => {
  const fileType = convertMimeTypeToFileType(mimeType);
  if (fileType) {
    return getLabelForFileType(fileType);
  }
  return "その他";
}

export const checkUploadFile = async (file: File | undefined, isStarter: boolean) : Promise<string | undefined> => {
  if (!file) {
    return;
  }

  const mimeType = file.type;
  const fileSizeMb = file.size / MB;

  const fileType = convertMimeTypeToFileType(mimeType);
  if (!fileType) {
    return (
      "無効なファイルタイプです。\n" +
      `アップロード可能なファイルタイプは以下の通りです。\n\n` +
      `画像: ${UploadableImageMimeTypes.map(type => convertMimeTypeToName(type)).join(', ')}\n` +
      // `ビデオ: ${UploadableVideoMimeTypes.map(type => convertMimeTypeToName(type)).join(', ')}\n` +
      `オーディオ: ${UploadableAudioMimeTypes.map(type => convertMimeTypeToName(type)).join(', ')}\n` +
      `ドキュメント: ${UploadableDocumentMimeTypes.map(type => convertMimeTypeToName(type)).join(', ')}\n` +
      `テキスト: ${UploadableTxtMimeTypes.map(type => convertMimeTypeToName(type)).join(', ')}`
    );
  }

  const maxFileSize = getUploadableMaxMbFor(fileType, isStarter);
  if (fileSizeMb > maxFileSize) {
    if (isStarter) {
      return (
        "Starterプランでは、ファイルサイズが下記のように制限されます。\n" +
        "サイズを調整いただくか、プランのアップグレードをご検討ください。\n\n" +
        `テキスト: ${UploadableTxtMaxMb}MBまで\n` +
        `テキスト以外: ${UploadableNotTextMaxMbForStarter}MBまで\n` +
        `(選択されたファイル: ${maxFileSize}MB)\n\n` +
        "※ 有料プランでの制限は下記のとおりです。\n" +
        `　テキスト: ${UploadableTxtMaxMb}MBまで\n` +
        `　画像: ${UploadableImageMaxMb}MBまで\n` +
        `　オーディオ: ${UploadableAudioMaxMb}MBまで\n` +
        `　ドキュメント: ${UploadableDocumentMaxMb}MBまで`
      )
    }
    return (
      "ファイルサイズが大きすぎます。\n" +
      `アップロード可能な最大ファイルサイズは以下の通りです。\n\n` +
      `画像: ${UploadableImageMaxMb}MB\n` +
      // `ビデオ: ${UploadableVideoMaxMb}MB\n` +
      `オーディオ: ${UploadableAudioMaxMb}MB\n` +
      `ドキュメント: ${UploadableDocumentMaxMb}MB\n` +
      `テキスト: ${UploadableTxtMaxMb}MB\n\n` +
      `(選択ファイルのサイズ: ${maxFileSize}MB)`
    );
  }

  // Starterの場合はFrontでPDFページ数と音声の長さもチェック
  // - 有料プランでは実質無制限だが、Starterだとエラーになることが多くなると推測しており、
  //   結構長い時間まってエラーになると心象が悪いため早めにだす。
  if (isStarter) {
    // PDFのページ数を判定
    if (fileType == FileType.DOCUMENT) {
      const UploadableDocumentMaxPages = 5; // 最大ページ数を設定

      try {
        const pdfPageCount = await getPdfPageCount(file);
        if (pdfPageCount > UploadableDocumentMaxPages) {
          return (
            `Starterプランでは、PDFのページ数は${UploadableDocumentMaxPages}ページまでとなっております。\n` +
            `ページ数を調整いただくか、プランのアップグレードをご検討ください。\n` +
            `(選択されたファイルのページ数: ${pdfPageCount}ページ)\n\n` +
            "※ 有料プランでのPDFアップロード制限は「20MBまで 3000ページまで」となっております。"
          );
        }
      } catch (err) {
        captureException(err);
        console.debug(err);
      }
    }

    // オーディオの長さを判定
    if (fileType == FileType.AUDIO) {
      const UploadableAudioMaxDuration = 60;
      try {
        const audioDuration = await getAudioDuration(file);
        if (audioDuration > UploadableAudioMaxDuration) {
          return (
            `Starterプランでは、音声の長さは${UploadableAudioMaxDuration}秒までとなっております。\n` +
            `長さを調整いただくか、プランのアップグレードをご検討ください。\n` +
            `(選択されたファイルの長さ: ${audioDuration}秒)\n\n` +
            "※ 有料プランでの音声アップロード制限は「100MBまで 8.4時間まで」となっております。"
          );
        }
      } catch (err) {
        captureException(err);
        console.debug(err);
      }
    }
  }


  return;
}

export const checkUploadFileAndModel = (file: File | FileUpload, modelIds: string[], currentMembership?: LoginUserMembership) : string | undefined => {
  // ファイルタイプを特定
  let fileType: FileType;
  if (file instanceof File) {
    const fileTypeTemp = convertMimeTypeToFileType(file.type);
    if (fileTypeTemp === null) {
      throw new Error('Invalid file type');
    }
    fileType = fileTypeTemp;
  } else {
    fileType = file.type;
  }

  // 送信対象となるモデルを取得
  if (modelIds.length === 0) {
    modelIds = [DEFAULT_AI_MODEL_ID];
  }
  const models = getAIModelConsts(modelIds);

  // 使っては行けないモデルを抽出
  const invalidModels: AIModelConst[] = []
  for (const model of models) {
    if (!model.uploadableFileTypes.includes(fileType)) {
      invalidModels.push(model);
    }
  }

  // 使っては行けないモデルがある場合、エラーメッセージを返す
  if (invalidModels.length > 0) {
    // どうサポートしてないかを表示
    const fileTypeLabel = getLabelForFileType(fileType);
    const mainMsg = (
      `下記のAIモデルは「${fileTypeLabel}」の添付をサポートしていません。\n` +
      "メンションまたは添付ファイルを見直してください。\n" +
      invalidModels.map(model => `・${model.name}`).join('\n')
    );

    // 送信可能なファイル情報メッセージを作成
    let additionalMsg = "";
    if (currentMembership) {
      console.log(currentMembership);
      const usableAiModelIds = currentMembership.team.quota.usableAiCodes || []
      console.log(currentMembership.team.quota);
      if (usableAiModelIds.length > 0) {
        additionalMsg = `\n\n[添付可能なファイル]`;

        const usableAiModels = getAIModelConsts(usableAiModelIds);
        for (const model of usableAiModels) {
          if (model.deprecated) {
            continue;
          }
          additionalMsg += `\n・${model.name} → ${
            model.uploadableFileTypes.map(type => getLabelForFileType(type)).join(', ')
          }`;
        }
      }
    }

    return mainMsg + additionalMsg;
  }

  return undefined;
}



export const formatBytes = (bytes: number): string => {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const getUploadableMaxMbFor = (fileType: FileType, isStarter: boolean) => {
  switch (fileType) {
    case FileType.TEXT:
      return UploadableTxtMaxMb;
    case FileType.IMAGE:
      return isStarter ? UploadableNotTextMaxMbForStarter : UploadableImageMaxMb;
    case FileType.AUDIO:
      return isStarter ? UploadableNotTextMaxMbForStarter : UploadableAudioMaxMb;
    case FileType.DOCUMENT:
      return isStarter ? UploadableNotTextMaxMbForStarter  : UploadableDocumentMaxMb;
  }
  return 0;
}

const getPdfPageCount = async (file: File): Promise<number> => {
  return new Promise<number>((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = async function () {
      const typedarray = new Uint8Array(this.result as ArrayBuffer);
      try {
        const pdf = await pdfjsLib.getDocument(typedarray).promise;
        resolve(pdf.numPages);
      } catch (err) {
        reject(err);
      }
    };

    fileReader.onerror = function (err) {
      reject(err);
    };

    fileReader.readAsArrayBuffer(file);
  });
};

const getAudioDuration = (file: File): Promise<number> => {
  return new Promise<number>((resolve, reject) => {
    const url = URL.createObjectURL(file);
    const audio = new Audio(url);

    const onLoadedMetadata = () => {
      resolve(audio.duration);
      URL.revokeObjectURL(url);
      audio.removeEventListener('loadedmetadata', onLoadedMetadata);
    };

    const onError = (e: Event) => {
      reject(e);
      URL.revokeObjectURL(url);
      audio.removeEventListener('error', onError);
    };

    audio.addEventListener('loadedmetadata', onLoadedMetadata);
    audio.addEventListener('error', onError);
  });
};
