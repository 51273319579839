import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@/store';
import apiAxios from '@/lib/axios';
import { captureException } from '@sentry/react';
import { useErrorModal } from '../../generic/hooks/useErrorModal';
import useLoading from '../../generic/hooks/useLoading';
import { SaveTeamConfig, TeamConfig } from '../team.type';
import { getCurrentMembership } from '../../auth/auth.type';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import AdminPageTitle from '../../admin/components/AdminPageTitle';
import { Button, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import { AdminContentsWrapper } from '../../admin/components/AdminUI';
import styled from '@emotion/styled';
import { useToast } from '../../generic/hooks/useToast';
import { updateTeamConfig } from '../../auth/auth.slice';

const configSchema = z.object({
  isFileAttachmentEnabled: z.union([z.boolean(), z.string().transform(val => val === 'true')]),
  aiModelConfigs: z.array(z.object({
    aiModelCode: z.string(),
    isDefault: z.boolean(),
    isEnabled: z.boolean(),
    description: z.string().optional(),
  })),
});

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  max-width: 400px;
`;

const TeamConfigForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const loginUser = useSelector((state: RootState) => state.auth.loginUser);
  const { setUnknownErrorModal } = useErrorModal();
  const { setIsLoading } = useLoading();
  const { showToast } = useToast();
  const { control, handleSubmit, reset, formState: { errors } } = useForm<SaveTeamConfig>({
    resolver: zodResolver(configSchema),
    defaultValues: {
      isFileAttachmentEnabled: false,
      aiModelConfigs: [],
    },
  });

  const team = getCurrentMembership(loginUser)?.team;

  // 最初に現在の設定を読み込む
  useEffect(() => {
    const getConfig = async () => {
      if (team) {
        setIsLoading(true);
        try {
          const response = await apiAxios.get<TeamConfig>(`/teams/${team.id}/config`);
          const teamConfig = response.data;
          reset({
            isFileAttachmentEnabled: teamConfig.isFileAttachmentEnabled,
            aiModelConfigs: teamConfig.aiModelConfigs.map(config => ({
              aiModelCode: config.aiModel.code,
              isDefault: config.isDefault,
              isEnabled: config.isEnabled,
              description: config.description,
            })),
          });
        } catch (error) {
          captureException(error);
          setUnknownErrorModal();
        } finally {
          setIsLoading(false);
        }
      }
    };
    getConfig();
  }, [team, setIsLoading, reset]);

  const updateConfig = async (data: SaveTeamConfig) => {
    if (team) {
      setIsLoading(true);
      try {
        const response = await apiAxios.put(`/teams/${team.id}/config`, data);
        dispatch(updateTeamConfig(response.data))
        showToast('チーム設定を更新しました。', 'success')
      } catch (error) {
        captureException(error);
        setUnknownErrorModal();
      } finally {
        setIsLoading(false);
      }
    }
  };

  return <>
    <AdminPageTitle title="機能設定" />
    <Divider />
    <AdminContentsWrapper>
      <StyledForm onSubmit={handleSubmit(updateConfig)}>
        <FormControl fullWidth margin="dense" variant="outlined" component="fieldset" error={Boolean(errors.isFileAttachmentEnabled)}>
          <FormLabel component="legend">ファイル添付機能</FormLabel>
          <Controller
            name="isFileAttachmentEnabled"
            control={control}
            render={({ field }) => (
              <RadioGroup row {...field} value={String(field.value)}>
                <FormControlLabel value="true" control={<Radio />} label="有効にする" />
                <FormControlLabel value="false" control={<Radio />} label="無効にする" />
              </RadioGroup>
            )}
          />
          {errors.isFileAttachmentEnabled && (
            <FormHelperText error>
              {errors.isFileAttachmentEnabled.message}
            </FormHelperText>
          )}
          <FormHelperText style={{ color: 'red' }}>
            [注意]<br/>
            ファイル添付機能はβバージョンのため、一部の動作が不安定な場合がございます。<br/>
            また添付されたファイルは禁止ワードやDLPの対象外となりますので、有効にする場合はご利用の際に十分にご注意ください。
          </FormHelperText>
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          color="primary"
        >
          保存
        </Button>
      </StyledForm>
    </AdminContentsWrapper>
  </>;
}

export default TeamConfigForm;